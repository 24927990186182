import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import NewsletterSignupF24 from "../newsletter-signup-f24"
import { FooterMenu1 } from "./footer-menu-1"
import { FooterMenu2 } from "./footer-menu-2"
import { FooterMenu3 } from "./footer-menu-3"

import IconFacebook from "../../assets/svg/facebook-f-brands.svg"
import IconInstagram from "../../assets/svg/instagram-brands.svg"
import IconTwitter from "../../assets/svg/twitter-brands.svg"

import Link from "../link"

const Footer = () => (
  <footer>
    <div className="bg-artwork-footer bg-tertiary">
      <NewsletterSignupF24 />

      <div className="page-footer__content">
        <div className="container py-4">
          <div className="text-center mb-3">
            <Link to="https://www.facebook.com/BlackgangChine/">
              <IconFacebook
                style={{ height: '30px', maxHeight: '30px', marginRight: '10px' }}
              />
            </Link>
            <Link to="https://twitter.com/BlackgangChine">
              <IconTwitter
                style={{ height: '30px', maxHeight: '30px', marginRight: '10px' }}
              />
            </Link>
            <Link to="https://www.instagram.com/blackgangchine/">
              <IconInstagram
                style={{ height: '30px', maxHeight: '30px', marginRight: '10px' }}
              />
            </Link>
          </div>

          <h2 className="h1 text-uppercase text-center text-white mb-3">Share your adventures<br /><span className="text-secondary">#BLACKGANGCHINE</span></h2>

          <div className="row py-4">
            <div className="col-12 col-md-4 col-lg-2 offset-lg-3 text-center">
              <FooterMenu1 />
            </div>
            <div className="col-12 col-md-4 col-lg-2 text-center">
              <FooterMenu2 />
            </div>
            <div className="col-12 col-md-4 col-lg-2 text-center">
              <FooterMenu3 />
            </div>
          </div>

        </div>
      </div>
    </div>

    <div className="bg-white">
      <div className="container py-4">
        <div className="row text-center">
          <div className="col footer__logos">
            <StaticImage
              src="../../assets/images/rf-miow-2021-silver.png"
              alt="Red Funnel My Isle of Wight Awards 2021 Silver Winner"
              className="mx-2 mb-3 mb-md-0"
            />
            <StaticImage
              src="../../assets/images/miow.png"
              alt="Red Funnel My Isle of Wight Awards 2018 Winner"
              className="mx-2 mb-3 mb-md-0"
            />
            <StaticImage
              src="../../assets/images/trip-advisor.png"
              alt="TripAdvisor certificate of excellence 2019"
              className="mx-2 mb-3 mb-md-0"
            />
            <StaticImage
              src="../../assets/images/gbb-logo.jpeg"
              alt="Great British Business"
              className="mx-2 mb-md-0"
            />
          </div>

          <p className="mt-4 mb-0"><small>Copyright {new Date().getFullYear()} &copy; All rights reserved. Developed by <Link to="https://brightbulbdesign.co.uk">Brightbulb Design</Link>.</small></p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer