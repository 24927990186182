import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"

import Navigation from "./navigation"
import LogoImg from "../assets/images/blackgang-logo-2022.png"

const Header = () => {
  const headerRef = useRef(null)

  useEffect(() => {
    const headerHeight = headerRef.current.clientHeight
    
    const shrinkHeader = function() {
      const distanceY = document.documentElement.scrollTop - headerHeight
      const shrinkOn = 15

      if(headerRef && headerRef.current !== undefined && headerRef.current !== null) {
        if (distanceY > shrinkOn) {
          headerRef.current.classList.add('shrunk')
        } else {
          if (distanceY < shrinkOn - headerHeight) {
            headerRef.current.classList.remove('shrunk')
          }
        }
      }
    }

    window.addEventListener("scroll", shrinkHeader, false)
  }, [])

  return (
    <header ref={headerRef} className="sticky-top">
      <div className="bg-primary">
        <nav className="navbar navbar-expand-xl">
          <div className="container flex-wrap align-items-center align-items-xl-stretch justify-content-between">
            <Link to="/" className="navbar-logo">
              <img src={LogoImg} className="header__logo py-2" width="224" height="89" alt="Blackgang Chine Logo"></img>
            </Link>
            
            <Navigation />
          </div>
        </nav>
      </div>
      <svg width="500" className="header__bottom" height="30" viewBox="0 0 500 25" preserveAspectRatio="none">
        <path d="M0,0 L0,5 Q250,45 500,5 L500,0 Z" fill="black" className="header__bottom-behind" />
        <path d="M0,0 L0,0 Q250,40 500,0 L500,0 Z" fill="black" className="header__bottom-foreground" />
      </svg>
    </header>
  )
}

export default Header